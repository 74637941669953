import React from 'react';

type Props = {
  content: string;
  startPlay: number;
  otherStyle?: string;
};

const VideoPlayer: React.FC<Props> = ({ content, otherStyle }) => {
  return (
    <video className={otherStyle} muted data-keepplaying autoPlay>
      <source src={content}></source>
    </video>
  );
};

export default VideoPlayer;
