import Describe from '@/components/describe';
import { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import VideoPlayer from '@/components/UI/videoPlayer';

import style from './videoBlockWithInfo.module.scss';

type Props = {
  title: string;
  describe: string;
  buttonText: string;
  cards: ReactNode[];
  content: string;
  reverse: boolean;
};

const VideoBlockWithInfo: React.FC<Props> = ({
  title,
  describe,
  buttonText,
  cards,
  content,
  reverse,
}) => {
  const { i18n } = useTranslation();
  const { windowWidth } = useWindowSize();

  const renderVideoUK = () =>
    windowWidth > 720 &&
    i18n.language === 'uk' && (
      <div className={style.block_video}>
        <VideoPlayer
          content={content}
          startPlay={200}
          otherStyle={style.playear}
        />
      </div>
    );

  const renderVideoEN = () =>
    windowWidth > 720 &&
    i18n.language === 'en' && (
      <div className={style.block_video}>
        <VideoPlayer
          content={content}
          startPlay={200}
          otherStyle={style.playear}
        />
      </div>
    );

  return (
    <section
      className={classNames(style.block, {
        [style.reverse]: reverse,
      })}
    >
      <Describe
        title={title}
        describe={describe}
        buttonText={buttonText}
        cardContent={cards}
      />
      {renderVideoUK()}
      {renderVideoEN()}
    </section>
  );
};

export default VideoBlockWithInfo;
