import { ReactNode, useMemo } from 'react';

import BlockInfo from '@/components/UI/blockInfo';
import CustomLink from '@/components/UI/link';

import style from './describe.module.scss';

type Props = {
  title: string;
  describe: string;
  buttonText: string;
  cardContent: string | ReactNode;
};

const Describe: React.FC<Props> = ({
  title,
  describe,
  buttonText,
  cardContent,
}) => {
  const renderCard = useMemo(() => {
    if (Array.isArray(cardContent)) {
      const qr = cardContent.length > 1;
      return (
        <div className={style.content_cards}>
          {cardContent.map((content, index) => (
            <BlockInfo
              otherStyle={index === 0 && qr ? style.qr : null}
              key={index}
            >
              {content}
            </BlockInfo>
          ))}
        </div>
      );
    }
  }, [cardContent]);

  return (
    <div className={style.content}>
      <h2 className={style.content_title}>{title}</h2>
      <p className={style.describe}>{describe}</p>
      {renderCard}
      <CustomLink
        href='/registration'
        target={false}
        style={style.button}
        text={buttonText}
      />
    </div>
  );
};

export default Describe;
