import { ReactNode } from 'react';
import style from './blockInfo.module.scss';

type Props = {
  children: ReactNode;
  otherStyle?: string | null;
};

const BlockInfo: React.FC<Props> = ({ children, otherStyle }) => {
  return <div className={otherStyle ?? style.card}>{children}</div>;
};

export default BlockInfo;
